.about-img {
  width: 100vw;
  height: auto;
}

.img-detective {
  display: flex;
  justify-content: center;

  margin: 70px;
}

.img-detective img {
  height: 30vh;
}

@media (max-width: 768px) {
  .img-detective {
    margin: 35px;
  }

  .img-detective img {
    height: 20vh;
  }
}
