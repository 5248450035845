@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

* {
  font-family: "Assistant", sans-serif;
}

.App {
  overflow: hidden;
}

#container {
  padding-top: 100px;
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin: 100px auto 0;
  background: #444;
  color: rgb(223, 223, 223);
  /* background-color: yellow; */
}

.section-title,
.home-section-title,
.second-title,
.section-text {
  width: 60vw;
}

.section-title,
.home-section-title {
  font-size: 32px;
  /* text-align: end; */
  padding: 15px 35px;
  margin-right: -10vw;
  text-decoration: underline;
}
.second-title {
  font-size: 32px;
  /* text-align: end; */
  padding: 35px 35px 0;
  margin-right: -10vw;
  /* text-decoration: underline; */
}
.section-text {
  font-size: 23px;
  text-align: start;
  padding: 0 35px;
  margin-right: -10vw;
}

.section-text:last-child {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .section-title,
  .home-section-title,
  .second-title,
  .section-text {
    width: 95vw;
  }

  .home-section-title {
    margin-top: 50vh;
  }

  .section-container {
    margin: 78px auto 0;
  }

  #container {
    padding-top: 0;
  }
}
