.header-fixed {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100vw;
  background-color: rgba(21, 21, 21, 1);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100%;
  color: #fff;
  line-height: 60px;
}

.navbar .logo {
  flex: 2;
  display: flex;
  justify-content: start;
  align-items: center;

  width: 100vw;
  /* margin-left: 180px; */
}
.navbar .logo img {
  width: 140px !important;
  height: auto;
  filter: invert(100%) sepia(51%) saturate(0%) hue-rotate(200deg)
    brightness(113%) contrast(101%);
  transition: all 0.3s ease;
}
.navbar .logo img:hover {
  filter: invert(12%) sepia(48%) saturate(3473%) hue-rotate(349deg)
    brightness(104%) contrast(122%);
}

.navbar nav {
  flex: 8;
}

.navbar label {
  user-select: none;
  cursor: pointer;
  padding: 28px 20px;
  position: relative;
  z-index: 3;
}

.navbar label i {
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
  font-style: normal;
  font-weight: normal;
}
.navbar label i:before,
.navbar label i:after {
  content: "";
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
.navbar label i,
.navbar label i:before,
.navbar label i:after {
  display: block;
  background: #eee;
}
.navbar label i:before {
  top: 5px;
}
.navbar label i:after {
  top: -5px;
}

.navbar #navbar-toggle {
  display: none;
}

.header #navbar-toggle:checked ~ .menu {
  visibility: visible;
  opacity: 0.99;
}

.header #navbar-toggle:checked ~ label {
  background: #212121;
  border-radius: 50%;
}
.header #navbar-toggle:checked ~ label i {
  background: transparent;
}
.header #navbar-toggle:checked ~ label i:before {
  transform: rotate(-45deg);
}
.header #navbar-toggle:checked ~ label i:after {
  transform: rotate(45deg);
}
.header #navbar-toggle:checked ~ label:not(.steps) i:before,
.header #navbar-toggle:checked ~ label:not(.steps) i:after {
  top: 0;
}

@media (max-width: 768px) {
  .navbar .logo {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40vw;
  }

  .navbar .logo img {
    width: 100px !important;
  }

  .navbar nav {
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-out;
    display: table;
    background: #ddd;
  }
  .navbar nav ul {
    margin: 0;
    padding: 20px 0;
    display: table-cell;
    vertical-align: middle;
  }
  .navbar nav li {
    display: block;
    text-align: center;
    padding: 20px 0;
    text-align: center;
    font-size: 50px;
    min-height: 50px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .navbar nav li:hover {
    background: #212121;
  }
  .navbar nav li:hover a {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease-out;
  }

  .navbar nav li a {
    color: #212121;
    display: block;
    padding: 0 0;
    font-size: 34px;
    line-height: 30px;
    transition: all 0.3s ease;
  }
}

@media (min-width: 768px) {
  .navbar nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    text-align: center;
    list-style: none;
  }
  .navbar nav li {
    flex: 1;
    transition: all 0.5s ease;
  }
  .navbar nav li a {
    display: block;
    padding: 0 0;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    transform: scale(1);
    transition: all 0.3s ease;
  }
  .navbar nav li.active {
    background: #555;
  }
  .navbar nav li:hover {
    background: #333;
  }
  .navbar nav li:hover a {
    color: #700303 !important;
    transform: scale(1.1);
  }
  .navbar label {
    display: none;
  }
}
