.contact-container {
  width: 100vw;
  padding: 0 50px;
  margin: 30px auto;
  /* background-color: rgba(0, 0, 0, 0.397); */
}

.form-title-container {
  margin-top: 30px;
  border: 2px solid #555;
  border-radius: 5px;
  box-shadow: 0px 0px 25px #888888;
  background-color: #333;
  padding: 35px;
  transition: all 0.3s ease;
  margin-bottom: 50px;
}
.form-title-container:hover {
  padding: 40px;
}

.contact-container form {
  width: 100%;
}

.contact-title {
  text-align: start;
  width: 450px;
  margin-bottom: 30px;
}

.contact-social {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.contact-social a {
  margin: 10px 15px;
  transition: all 0.3s ease;
}

.contact-social a:hover {
  margin: 10px 20px;
}

@media (max-width: 768px) {
  .contact-container {
    width: 80vw;
    padding: 0 50px;
    margin: 30px auto;
    background-color: rgba(0, 0, 0, 0.397);
  }

  .form-title-container {
    width: 80vw;
  }

  .contact-title {
    width: 80vw;
  }

  .contact-container form {
    width: 100%;
  }
}
