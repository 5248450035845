.footer {
  background-color: rgba(21, 21, 21, 1);
  color: rgb(223, 223, 223);
  padding: 30px 20px 10px;

  z-index: 2;
}

.footer-collumn {
  padding: 0 50px;
  border-right: 1px solid rgb(127, 132, 133);
}

.title {
  text-align: start;
  text-decoration: underline;

  margin-bottom: 35px;
}

.footer-list {
  list-style: none;
}

.small-title {
  text-align: start;
}

.footer-list li {
  text-align: start;
}
.footer-list li a {
  color: rgb(223, 223, 223);
  text-decoration: none;
  transition: all 0.3s ease;
}
.footer-list li a:after {
  content: "";
  width: 0px;
  height: 1px;
  display: block;
  background: #555;
  transition: 300ms;
}
.footer-list li:hover a:after {
  width: 50%;
}

input,
textarea {
  text-align: right;
}

.map {
  margin: auto;
  width: 15vw;
}

@media (max-width: 768px) {
  .footer-collumn {
    border-right: none;
  }

  .title {
    text-align: start;
    text-decoration: underline;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .footer-list li {
    width: 160%;
  }

  .map {
    margin: auto;
    width: 100%;
  }
}
