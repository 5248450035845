.img-grid-container {
  width: 100vw;
}

.game-board {
  display: grid;
  grid-template-rows: 60vh 60vh 60vh;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
}

.box {
  background: #444;
  border: 1px solid #555;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
}

.box-text {
  display: flex;
  flex-direction: column;
  text-align: end;
  padding: 15%;
  min-height: 50vh;
}

.box-text h4 {
  text-align: start;
  text-decoration: underline;
}
.box-text p {
  font-size: 23px;
  text-align: start;
}

.grid-img {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  transition: all 0.3s ease;
}
.grid-img:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .game-board {
    display: grid;
    grid-template-rows: 50vh 50vh 50vh;
    grid-template-columns: repeat(1, 1fr);
  }
}
