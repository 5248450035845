@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.header-fixed {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100vw;
  background-color: rgba(21, 21, 21, 1);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100%;
  color: #fff;
  line-height: 60px;
}

.navbar .logo {
  flex: 2 1;
  display: flex;
  justify-content: start;
  align-items: center;

  width: 100vw;
  /* margin-left: 180px; */
}
.navbar .logo img {
  width: 140px !important;
  height: auto;
  -webkit-filter: invert(100%) sepia(51%) saturate(0%) hue-rotate(200deg)
    brightness(113%) contrast(101%);
          filter: invert(100%) sepia(51%) saturate(0%) hue-rotate(200deg)
    brightness(113%) contrast(101%);
  transition: all 0.3s ease;
}
.navbar .logo img:hover {
  -webkit-filter: invert(12%) sepia(48%) saturate(3473%) hue-rotate(349deg)
    brightness(104%) contrast(122%);
          filter: invert(12%) sepia(48%) saturate(3473%) hue-rotate(349deg)
    brightness(104%) contrast(122%);
}

.navbar nav {
  flex: 8 1;
}

.navbar label {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  padding: 28px 20px;
  position: relative;
  z-index: 3;
}

.navbar label i {
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
  font-style: normal;
  font-weight: normal;
}
.navbar label i:before,
.navbar label i:after {
  content: "";
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
.navbar label i,
.navbar label i:before,
.navbar label i:after {
  display: block;
  background: #eee;
}
.navbar label i:before {
  top: 5px;
}
.navbar label i:after {
  top: -5px;
}

.navbar #navbar-toggle {
  display: none;
}

.header #navbar-toggle:checked ~ .menu {
  visibility: visible;
  opacity: 0.99;
}

.header #navbar-toggle:checked ~ label {
  background: #212121;
  border-radius: 50%;
}
.header #navbar-toggle:checked ~ label i {
  background: transparent;
}
.header #navbar-toggle:checked ~ label i:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.header #navbar-toggle:checked ~ label i:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.header #navbar-toggle:checked ~ label:not(.steps) i:before,
.header #navbar-toggle:checked ~ label:not(.steps) i:after {
  top: 0;
}

@media (max-width: 768px) {
  .navbar .logo {
    flex: 3 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 40vw;
  }

  .navbar .logo img {
    width: 100px !important;
  }

  .navbar nav {
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-out;
    display: table;
    background: #ddd;
  }
  .navbar nav ul {
    margin: 0;
    padding: 20px 0;
    display: table-cell;
    vertical-align: middle;
  }
  .navbar nav li {
    display: block;
    text-align: center;
    padding: 20px 0;
    text-align: center;
    font-size: 50px;
    min-height: 50px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .navbar nav li:hover {
    background: #212121;
  }
  .navbar nav li:hover a {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease-out;
  }

  .navbar nav li a {
    color: #212121;
    display: block;
    padding: 0 0;
    font-size: 34px;
    line-height: 30px;
    transition: all 0.3s ease;
  }
}

@media (min-width: 768px) {
  .navbar nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    text-align: center;
    list-style: none;
  }
  .navbar nav li {
    flex: 1 1;
    transition: all 0.5s ease;
  }
  .navbar nav li a {
    display: block;
    padding: 0 0;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all 0.3s ease;
  }
  .navbar nav li.active {
    background: #555;
  }
  .navbar nav li:hover {
    background: #333;
  }
  .navbar nav li:hover a {
    color: #700303 !important;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .navbar label {
    display: none;
  }
}

h1 {
  font-size: 3em;
  text-align: center;
  color: black;
  margin: 0;
  padding: 30vh 0 1em;
}
h2 {
  text-align: center;
  white-space: nowrap;
  color: black;
}
a {
  text-decoration: none;
  color: black;
}
p {
  text-align: left;
}
.popup-btn {
  font-size: 32px;
  color: white;
  letter-spacing: 3px;
  position: fixed;
  top: 40%;
  left: -20px;
  margin-left: -35px;
  -webkit-transform: rotate(-270deg);
          transform: rotate(-270deg);
  text-decoration: none;
  transition: all 0.5s ease;
  border: 5px solid white;
  border-radius: 15px;
  padding: 15px;
}

.popup-btn:hover {
  margin-left: 0;
  color: white;

  text-decoration: underline;
}

.popup {
  position: fixed;
  padding: 10px;
  width: 500px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgb(179, 179, 179);
  visibility: hidden;
  opacity: 0;
  /* "delay" the visibility transition */
  transition: opacity 0.5s, visibility 0s linear 0.5s;
  z-index: 1;
}
.popup:target {
  visibility: visible;
  opacity: 1;
  /* cancel visibility transition delay */
  transition-delay: 0s;
}
.popup-close {
  position: absolute;
  padding: 10px;
  width: 500px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
}
.popup .close {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 10px;
  color: #000;
  transition: color 0.3s;
  font-size: 2em;
  line-height: 0.6em;
  font-weight: bold;
}
.popup .close:hover {
  color: #c90000;
}

.close-popup {
  background: rgba(0, 0, 0, 0.7);
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  /* "delay" the visibility transition */
  transition: opacity 0.5s, visibility 0s linear 0.5s;
}
.popup:target + .close-popup {
  opacity: 1;
  visibility: visible;
  /* cancel visibility transition delay */
  transition-delay: 0s;
}

.form-text {
  width: 100%;
}

.form-text option {
  text-align: right;
}

.message-to-user {
  padding: 40px;
  text-align: center;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submit-btn {
  align-self: center;
}

@media (max-width: 768px) {
  .popup-btn {
    display: none;
  }
  .popup {
    width: 90vw;
  }
  .popup-close {
    width: 90vw;
  }
}

.about-img {
  width: 100vw;
  height: auto;
}

.img-detective {
  display: flex;
  justify-content: center;

  margin: 70px;
}

.img-detective img {
  height: 30vh;
}

@media (max-width: 768px) {
  .img-detective {
    margin: 35px;
  }

  .img-detective img {
    height: 20vh;
  }
}

.contact-container {
  width: 100vw;
  padding: 0 50px;
  margin: 30px auto;
  /* background-color: rgba(0, 0, 0, 0.397); */
}

.form-title-container {
  margin-top: 30px;
  border: 2px solid #555;
  border-radius: 5px;
  box-shadow: 0px 0px 25px #888888;
  background-color: #333;
  padding: 35px;
  transition: all 0.3s ease;
  margin-bottom: 50px;
}
.form-title-container:hover {
  padding: 40px;
}

.contact-container form {
  width: 100%;
}

.contact-title {
  text-align: start;
  width: 450px;
  margin-bottom: 30px;
}

.contact-social {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.contact-social a {
  margin: 10px 15px;
  transition: all 0.3s ease;
}

.contact-social a:hover {
  margin: 10px 20px;
}

@media (max-width: 768px) {
  .contact-container {
    width: 80vw;
    padding: 0 50px;
    margin: 30px auto;
    background-color: rgba(0, 0, 0, 0.397);
  }

  .form-title-container {
    width: 80vw;
  }

  .contact-title {
    width: 80vw;
  }

  .contact-container form {
    width: 100%;
  }
}

.img-grid-container {
  width: 100vw;
}

.game-board {
  display: grid;
  grid-template-rows: 60vh 60vh 60vh;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
}

.box {
  background: #444;
  border: 1px solid #555;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
}

.box-text {
  display: flex;
  flex-direction: column;
  text-align: end;
  padding: 15%;
  min-height: 50vh;
}

.box-text h4 {
  text-align: start;
  text-decoration: underline;
}
.box-text p {
  font-size: 23px;
  text-align: start;
}

.grid-img {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  transition: all 0.3s ease;
}
.grid-img:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .game-board {
    display: grid;
    grid-template-rows: 50vh 50vh 50vh;
    grid-template-columns: repeat(1, 1fr);
  }
}

.homeContact {
  position: absolute;
  top: 30vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 20vw;
  opacity: 0.85;
  text-align: center;
  background: grey;
  padding: 10px 15px;
  border-radius: 5px;
}

#nav-logo {
  width: 240px;
  height: auto;
  color: #ff0000;
  -webkit-filter: invert(12%) sepia(48%) saturate(3473%) hue-rotate(349deg)
    brightness(104%) contrast(122%);
          filter: invert(12%) sepia(48%) saturate(3473%) hue-rotate(349deg)
    brightness(104%) contrast(122%);
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .homeContact {
    top: 33vh;
    width: 90vw;
  }
}

.header img {
  width: 100vw;
  height: 95vh;
}

.logo-main-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 25vh;
}

.logo-main-container img {
  width: 45%;
  height: auto;
  -webkit-filter: invert(12%) sepia(48%) saturate(3473%) hue-rotate(349deg)
    brightness(104%) contrast(122%);
          filter: invert(12%) sepia(48%) saturate(3473%) hue-rotate(349deg)
    brightness(104%) contrast(122%);
}

.text-and-image {
  margin-bottom: 40px;
}

.group-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}

@media (max-width: 768px) {
  .header img {
    width: 100vw;
    height: auto;
  }

  .logo-main-container {
    top: 14vh;
  }
}

.invest-list {
  /* list-style: none; */
  width: 65vw;
}

.list-item {
  /* text-align: end; */
  font-size: 1.25rem;
  transition: all 0.3s ease;
}
.list-text {
  /* text-align: end; */
  font-size: 1.25rem;
  line-height: 36px;
  width: 50vw;
}

.bold-text {
  width: 50vw;
  font-size: 1.4rem;
  margin: 5vw;
}

.practice-img img {
  width: 50vw;
  height: auto;
  border-radius: 5px;
  margin-bottom: 35px;
}
/* .list-item:hover {
  font-size: 1.4rem;
} */
@media (max-width: 768px) {
  .invest-list {
    width: 90vw;
  }

  .list-text {
    /* text-align: end; */
    font-size: 1.25rem;
    width: 70vw;
  }

  .practice-img {
    display: none;
  }

  .bold-text {
    width: 70vw;
  }
}

.footer {
  background-color: rgba(21, 21, 21, 1);
  color: rgb(223, 223, 223);
  padding: 30px 20px 10px;

  z-index: 2;
}

.footer-collumn {
  padding: 0 50px;
  border-right: 1px solid rgb(127, 132, 133);
}

.title {
  text-align: start;
  text-decoration: underline;

  margin-bottom: 35px;
}

.footer-list {
  list-style: none;
}

.small-title {
  text-align: start;
}

.footer-list li {
  text-align: start;
}
.footer-list li a {
  color: rgb(223, 223, 223);
  text-decoration: none;
  transition: all 0.3s ease;
}
.footer-list li a:after {
  content: "";
  width: 0px;
  height: 1px;
  display: block;
  background: #555;
  transition: 300ms;
}
.footer-list li:hover a:after {
  width: 50%;
}

input,
textarea {
  text-align: right;
}

.map {
  margin: auto;
  width: 15vw;
}

@media (max-width: 768px) {
  .footer-collumn {
    border-right: none;
  }

  .title {
    text-align: start;
    text-decoration: underline;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .footer-list li {
    width: 160%;
  }

  .map {
    margin: auto;
    width: 100%;
  }
}

.social {
  position: fixed;
  bottom: 25px;
  left: 25px;

  display: flex;
  flex-direction: column;
}

#phone-button {
  display: none;
  position: relative;
  background: rgb(255, 196, 0);
  padding: 1em;
  border-radius: 50%;
}

#phone-button img {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 23px;
  left: 0;
  right: 0;
  top: 12px;
  text-align: center;
  -webkit-filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(223deg)
    brightness(111%) contrast(101%);
          filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(223deg)
    brightness(111%) contrast(101%);
}

.social a {
  margin: 5px;
  transition: all 0.3s ease;
}
.social a:hover {
  margin: 15px 5px;
}

.whatapp-inner {
  display: none;
}

@media (max-width: 768px) {
  .social a {
    height: 45px;
  }

  #phone-button {
    display: inline;
  }
}

* {
  font-family: "Assistant", sans-serif;
}

.App {
  overflow: hidden;
}

#container {
  padding-top: 100px;
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin: 100px auto 0;
  background: #444;
  color: rgb(223, 223, 223);
  /* background-color: yellow; */
}

.section-title,
.home-section-title,
.second-title,
.section-text {
  width: 60vw;
}

.section-title,
.home-section-title {
  font-size: 32px;
  /* text-align: end; */
  padding: 15px 35px;
  margin-right: -10vw;
  text-decoration: underline;
}
.second-title {
  font-size: 32px;
  /* text-align: end; */
  padding: 35px 35px 0;
  margin-right: -10vw;
  /* text-decoration: underline; */
}
.section-text {
  font-size: 23px;
  text-align: start;
  padding: 0 35px;
  margin-right: -10vw;
}

.section-text:last-child {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .section-title,
  .home-section-title,
  .second-title,
  .section-text {
    width: 95vw;
  }

  .home-section-title {
    margin-top: 50vh;
  }

  .section-container {
    margin: 78px auto 0;
  }

  #container {
    padding-top: 0;
  }
}

.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  top: 45vw;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #700303 solid;
  border-bottom: 2px #700303 solid;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #700303 solid;
  border-bottom: 1px #700303 solid;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: arrow;
          animation-name: arrow;
}

.arrow:hover:before {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;

  -webkit-animation-name: arrow2;

          animation-name: arrow2;
}

@-webkit-keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
}
@-webkit-keyframes arrow2 {
  50% {
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
}
@keyframes arrow2 {
  50% {
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
}

@media (max-width: 768px) {
  .arrow {
    display: none;
  }
}

