.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  top: 45vw;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #700303 solid;
  border-bottom: 2px #700303 solid;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #700303 solid;
  border-bottom: 1px #700303 solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

.arrow:hover:before {
  animation-duration: 1s;

  animation-name: arrow2;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}
@keyframes arrow2 {
  50% {
    transform: translate(-10px, -10px);
  }
}

@media (max-width: 768px) {
  .arrow {
    display: none;
  }
}
